.boundary {
  align-items: center;
  background: #fff;
  display: flex;
  height: calc(100% - 56px);
  justify-content: center;
  width: 100%;
}

.button__primary {
  align-items: center;
  background-color: #008060;
  border: 1px solid #babfc3;
  border-radius: 0.4rem;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  justify-content: center;
  line-height: 1;
  margin-top: 1.5rem;
  margin-right: 0.6em;
  min-height: 3.6rem;
  min-width: 3.6rem;
  padding: 0.7rem 1.6rem;
  text-align: center;
}

.button__primary:active {
  background-color: #005e47 !important;
}

.button__primary:hover {
  background-color: #006e53;
}

.errorContent {
  align-items: center;
  display: flex;
}

.errorMessage {
  align-items: flex-start;
  color: #6d7175;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  gap: 1.5em;
  line-height: 20px;
  z-index: 10;
}

.errorHeading {
  color: #000;
  font-family: 'Source Sans Pro';
  font-size: 43px !important;
  font-weight: 600;
  line-height: 44px;
}

.link {
  text-decoration: none !important;
}
