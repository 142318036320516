.theme {
  color: #595959;
  line-height: 1.5;
}

.theme h2 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
}

.theme a {
  color: #4078c0;
  text-decoration: underline;
}

.theme br {
  display: inline-block;
  width: 100%;
  content: '';
}

.theme ol {
  padding-left: 2em;
  list-style-type: decimal;
}

.theme li {
  margin-top: 8px;
}

.theme p {
  margin-bottom: 8px;
}

.theme img {
  padding: 25px 0 0;
  margin: auto;
}
