.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 34px;
  padding-right: 42px;
}

.title {
  display: flex;
  flex-direction: column;
}

.title > h1 {
  font-size: 28px;
  padding-bottom: 4px;
}

.title > h2 {
  font-size: 14px;
  color: #6d7175;
  font-weight: 400;
  padding-top: 4px;
}

.addons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.addon {
  margin-left: 8px;
  display: flex;
}
