@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');

div,
p,
button {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.Polaris-Frame__Content {
  background: #fafbfb;
}

.context-page {
  background: #fafbfb;
  padding-top: 38px;
}

.context-subpage {
  background: #ffffff;
  padding-top: 38px;
  padding-left: 34px;
  padding-right: 42px;
}
