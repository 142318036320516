.rule {
  display: flex;
  flex-direction: column;
  background: #f2f7fe;
  border-radius: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 38px;
  padding-right: 38px;
}

.ruleHead {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  justify-content: flex-end;
}

.ruleHead > a {
  font-size: 14px;
}

.ruleHead .if {
  font-weight: 800;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.deleteRule {
  margin-left: 22px;
  cursor: pointer;
}

.ruleBody {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ruleFooter {
  display: flex;
  justify-content: flex-end;
}
