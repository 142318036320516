.table {
  display: flex;
  flex-direction: column;
}

.head {
  display: grid;
  grid-template-columns: 50px 4fr 1fr 1fr 1fr 1fr 1fr 1fr 100px 100px 50px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: inset 0px -1px 0px #e1e3e5;
}

.head > div:first-child {
  grid-column-start: 2;
}

.body {
  display: flex;
  flex-direction: column;
}

.row {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  box-shadow: inset 0px -1px 0px #e1e3e5;
  font-weight: 400;
}

.row:hover {
  background-color: #f2f2f2;
}

.rowContainer {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr 1fr 1fr 1fr 100px 100px;
  width: 100%;
  cursor: default;
}

.rowDisabled {
  background-color: #fafbfb;
  color: #6d7175;
}

.cell {
  height: 52px;
  display: flex;
  align-items: center;
}

.cell:first-child {
  cursor: pointer;
}

.draggable {
  cursor: grab;
}

.draggable:focus {
  cursor: grabbing;
}

.icons {
  display: flex;
  align-items: center;
}
