.block {
  background: rgba(246, 246, 247, 1);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: inset 0px -1px 0px #e1e3e5;
  height: fit-content;
  border: 1px solid #e1e3e5;
}

.blockBody {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.blockHead {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 800;
  width: 100%;
  display: flex;
  align-items: center;
  height: 52px;
  box-shadow: inset 0px -1px 0px #e1e3e5;
}

.blockHeadLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.blockHeadRight {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  justify-content: center;
}

.blockVariation {
  background: #ffffff;
  padding-left: 17px;
  padding-right: 17px;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0px -1px 0px #e1e3e5;
}

.blockVariation.active {
  background: #aee9d1;
}

.blockCircle {
  position: relative;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 2px solid #8c9196;
  box-sizing: border-box;
  border-radius: 100px;
  margin-right: 16px;
}

.blockPreview {
  border-radius: 4px;
  border: 1px solid #c9cccf;
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

.blockPreview > div {
  background-size: contain;
  width: 40px;
  height: 40px;
}

.blockVariation.active > .blockCircle::after,
.blockVariation:hover > .blockCircle::after {
  content: ' ';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: #2c6ecb;
  border-radius: 100px;
  z-index: 100;
}

.blockVariation:hover > .blockCircle::after {
  background: #8c9196;
}

.blockVariation.active > .blockCircle::after {
  background: #2c6ecb;
}

.blockFooter {
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 16px;
  color: #6d7175;
  font-size: 14px;
  justify-content: space-between;
}

.showMore {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.showAll {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.showAll p {
  text-decoration: underline;
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 24px;
}

.filter > div {
  max-width: 225px;
}

.selectedVariation {
  font-weight: 400;
  font-size: 12px;
  color: #6d7175;
  margin-left: 24px;
}
