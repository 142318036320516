.switch {
  position: relative;
  background: #b9bdc2;
  border-radius: 8px;
  width: 46px;
  height: 16px;
  cursor: pointer;
}
.switch.loading {
  cursor: wait;
}

.switch.active {
  background: #008060;
}

.label {
  position: relative;
  left: 18px;
  top: -2px;
  color: #fafbfb;
  font-size: 12px;
  transition: left 0.2s ease-in;
}

.switch.active > .label {
  left: 4px;
}

.circle {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  background: #fafbfb;
  border-radius: 9999px;
  transition: left 0.2s ease-in;
}

.switch.active > .circle {
  left: 32px;
}
