@import '~@shopify/polaris-tokens/dist/colors.custom-properties.css';

.toolbar {
  background-color: white;
  color: #6d7175;
  height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e3e5;
}

.toolbarLeft {
  align-items: center;
  display: flex;
  gap: 32px;
  margin-left: 34px;
  height: inherit;
}

.toolbarRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 46px;
  margin-left: auto;
  height: inherit;
}

.toolbarItem {
  align-items: center;
  display: flex;
  border-bottom: medium solid transparent;
  border-top: medium solid transparent;
  height: 100%;
  justify-items: center;
}

.toolbarLeft .toolbarItem:hover {
  border-bottom: medium solid #999ea4;
  color: #000;
}

.toolbarLeft .active {
  border-bottom: medium solid var(--color-green-dark);
}

.help {
  height: 56px;
}

.dropdown {
  display: none;
  left: 0;
  position: absolute;
  padding-top: 12px;
  top: 100%;
  z-index: 1;
}

.dropdownMenu {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  padding: 16px;
  width: max-content;
}

.dropdownItem {
  border-radius: 8px;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}

.dropdownItem:hover {
  background: #f1f2f3;
}

.dropdownItem:active {
  background: #ebebeb;
}

.help_hover {
  border-bottom: medium solid var(--color-green-dark) !important;
}
